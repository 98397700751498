import { useRef } from "react";
import style from "./account-switcher.module.css";
import { TipName } from "shared/datamodel/schemas";
import WalkthroughTipPresenter from "frontend/walkthrough-tips/walkthrough-tip-presenter";
import { WalkthroughTipType } from "frontend/walkthrough-tips/walkthrough-tip-model";
export function AccountSwitcher({ accountName }: { accountName: string }) {
  const menuButtonRef = useRef<HTMLDivElement>(null);
  const firstLetter = accountName[0].toUpperCase();

  return (
    <div className={style.container} ref={menuButtonRef}>
      <div className={style.content}>
        <div className={style.thumbnail}>{firstLetter}</div>
        <span>{`${accountName} account`}</span>
      </div>
      <WalkthroughTipPresenter
        tipName={TipName.walkthroughAccountName}
        attachToRef={menuButtonRef}
        side="left"
        type={WalkthroughTipType.HomePage}
      />
    </div>
  );
}
