import React, { useEffect } from "react";
import style from "./confirm-create-canvas-modal.module.css";
import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import { InstanceType } from "shared/datamodel/schemas/ability";
import { CloseIcon } from "frontend/icons/close";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import { SharedCanvasIcon } from "frontend/icons/shared-canvas";

const buttonSize = {
  width: 226,
  height: 45,
  borderRadius: 6,
  ontFamily: "Poppins",
  fontSize: 16,
  fontWeight: 400,
};

export default function ConfirmCreateCanvasModal({
  type,
  onCancel,
  onCreate,
}: {
  type: InstanceType;
  onCancel: () => void;
  onCreate: () => void;
}) {
  useEffect(() => {
    tracking.trackAnalyticsEvent("confirm-create-canvas-in-team-or-project-modal-shown", {
      category: consts.TRACKING_CATEGORY.COLLABORATION,
    });
  }, []);

  return (
    <div className={style.modalContainer}>
      <div className={style.closeIcon} onClick={onCancel}>
        <CloseIcon />
      </div>
      <div className={style.container}>
        <SharedCanvasIcon />
        <div className={style.contentWrapper}>
          <h2 className={style.title}>{`Creating in a shared ${type}?`}</h2>
          <p className={style.description}>
            {`The created canvas will be shared to the same people as the selected ${type}.`}
          </p>
        </div>
        <div className={style.buttonContainer}>
          <StyledButton
            onClick={() => {
              tracking.trackAnalyticsEvent("confirm-create-canvas-in-team-or-project-canceled", {
                category: consts.TRACKING_CATEGORY.COLLABORATION,
              });
              onCancel();
            }}
            style={ButtonStyle.outline}
            title={"Cancel"}
            customStyle={{ ...buttonSize, color: "#1973FF" }}
          />
          <StyledButton
            onClick={() => {
              tracking.trackAnalyticsEvent("confirm-create-canvas-in-team-or-project-created", {
                category: consts.TRACKING_CATEGORY.COLLABORATION,
              });
              onCreate();
            }}
            title={"Create"}
            customStyle={{ ...buttonSize, color: "#FFF" }}
          />
        </div>
      </div>
    </div>
  );
}
