import React, { useState, useRef, useEffect } from "react";
import { useHover } from "usehooks-ts";
import { Project } from "shared/datamodel/schemas/project";
import { addToLocalStorageArray, getLocalStorageItem, setLocalStorageItem } from "frontend/utils/storage-utils";
import { TeamWithProjects } from "shared/datamodel/schemas/team";
import { useTeamsWithProjects } from "frontend/hooks/caching/use-teams-with-projects";
import consts, { TEAMS_AND_PROJECTS_LOCAL_STORAGE_KEY } from "shared/consts";
import ProjectList from "./projects-list";
import style from "./team-project-hierarchy.module.css";
import { NewTeamOrProjectIcon } from "frontend/icons/new-team-or-project";
import { TeamCellNew } from "./team-cell-new";
import useAbility from "frontend/hooks/use-ability";
import useStateValue from "frontend/state/value";
import { InstanceType } from "shared/datamodel/schemas/ability";
import { CreateProject } from "./create-project";
import { CanvasTemplate } from "shared/datamodel/schemas";
import { useTeamsCache } from "frontend/hooks/caching/use-teams-cache";
import { CloseTeamIcon } from "frontend/icons/close-team";
import { OpenTeamIcon } from "frontend/icons/open-team";
import { CreateTeam } from "./create-team";
import classNames from "classnames";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";
import { TeamsIcon } from "frontend/icons/teams";
import tracking from "frontend/tracking";

export function TeamProjectHierarchy({
  selectedTeamId,
  setSelectedTeam,
  selectedProjectId,
  setSelectedProject,
  resetSelectedInstance,
  onEditSeats,
  setShowRestrictedModal,
  handleNoPermissionToCreate,
  createNewBoard,
}: {
  selectedTeamId: number | null;
  setSelectedTeam: (team: TeamWithProjects) => void;
  selectedProjectId: number | null;
  setSelectedProject: (project: Project) => void;
  resetSelectedInstance: () => void;
  onEditSeats: () => void;
  setShowRestrictedModal: (show: boolean) => void;
  handleNoPermissionToCreate: () => void;
  createNewBoard: ({
    template,
    inputProjectId,
    inputTeamId,
    source,
  }: {
    template: CanvasTemplate;
    inputProjectId: number | null;
    inputTeamId: number | null;
    source: string;
  }) => void;
}) {
  const [{ user }] = useStateValue();
  const [expandedTeams, setExpandedTeams] = useState<number[]>([]);
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [projectParentTeamId, setProjectParentTeamId] = useState<number | null>(null);
  const { canPerformAnyAction, canCreateInstance } = useAbility();
  const { getTeams } = useTeamsCache();
  const newTeamRef = useRef<HTMLDivElement | null>(null);
  const hover = useHover(newTeamRef);
  const { teamsWithProjects } = useTeamsWithProjects();

  const toggleTeam = (teamId: number) => {
    setExpandedTeams((previousTeams) =>
      previousTeams.includes(teamId) ? previousTeams.filter((id) => id !== teamId) : [...previousTeams, teamId]
    );
  };

  useEffect(() => {
    if (selectedProjectId && expandedTeams.length === 0) {
      const team = teamsWithProjects.find((team) => team.projects?.find((project) => project.id === selectedProjectId));
      if (team) {
        setExpandedTeams([team.id]);
      }
    }
  }, [selectedProjectId, teamsWithProjects]);

  const handleProjectSelection = (project: Project) => {
    setSelectedProject(project);
    const selectedTeamsAndProjects = getLocalStorageItem(TEAMS_AND_PROJECTS_LOCAL_STORAGE_KEY);
    setLocalStorageItem(
      TEAMS_AND_PROJECTS_LOCAL_STORAGE_KEY,
      selectedTeamsAndProjects.filter(
        (item: { selectedTeam: number; selectedProject: number }) => item.selectedTeam !== selectedTeamId
      )
    );
    addToLocalStorageArray(TEAMS_AND_PROJECTS_LOCAL_STORAGE_KEY, {
      selectedTeam: selectedTeamId,
      selectedProject: project.id,
    });
  };

  function renderProjects(team: TeamWithProjects) {
    return (
      <>
        {expandedTeams.includes(team.id) && (
          <ProjectList
            key={team.id}
            team={team}
            selectedProjectId={selectedProjectId}
            handleProjectSelection={handleProjectSelection}
            onEditSeats={onEditSeats}
            createNewBoard={createNewBoard}
            onDeleteSelectedProject={resetSelectedInstance}
            handleNewProjectClick={handleNewProjectClick}
          />
        )}
      </>
    );
  }

  const [isLoadingTeam, setIsLoadingTeam] = useState(false);

  async function handleNewProjectClick(parentTeamId: number) {
    setIsLoadingTeam(true);
    const teams = await getTeams([String(parentTeamId)]);
    toggleTeam(parentTeamId);
    setIsLoadingTeam(false);

    if (!teams || teams.length === 0) return;
    const fullTeam = teams[0];

    const shouldShoWRestrictedModal = user && !canPerformAnyAction(user, fullTeam, InstanceType.Team);
    if (shouldShoWRestrictedModal) {
      setShowRestrictedModal(true);
    } else if (user && !canCreateInstance(user, fullTeam, InstanceType.Team)) {
      handleNoPermissionToCreate();
    } else {
      setProjectParentTeamId(parentTeamId);
      setShowCreateProjectModal(true);
    }
  }

  const handleTeamSelection = (team: TeamWithProjects) => {
    setSelectedTeam(team);
    const selectedTeamsAndProjects = (getLocalStorageItem("selectedTeamsAndProjects") ?? []) as {
      selectedTeam: number;
      selectedProject: number;
    }[];
    const selectedProjectId =
      selectedTeamsAndProjects.find(
        (item: { selectedTeam: number; selectedProject: number }) => item.selectedTeam === team.id
      )?.selectedProject ?? null;
    setLocalStorageItem(
      "selectedTeamsAndProjects",
      selectedTeamsAndProjects.filter(
        (item: { selectedTeam: number; selectedProject: number }) => item.selectedTeam !== team.id
      )
    );
    addToLocalStorageArray("selectedTeamsAndProjects", {
      selectedTeam: team.id,
      selectedProject: selectedProjectId,
    });
  };

  return (
    <>
      <div className={style.container}>
        <div className={style.title}>
          <div className={style.allTeams}>
            <TeamsIcon />
            <span>My Teams</span>
          </div>
          <div
            className={style.newTeam}
            onClick={() => {
              tracking.trackAnalyticsEvent("create-team-clicked", {
                category: consts.TRACKING_CATEGORY.TEAM_ACTION,
                isNewHomePage: true,
              });
              setShowCreateTeamModal(true);
            }}
            ref={newTeamRef}
          >
            {hover && (
              <Tooltip
                side="top"
                label={"Create new team"}
                relativeTo={newTeamRef}
                customStyle={{
                  backgroundColor: "#113357",
                  borderRadius: 4,
                  color: " #FFF",
                  fontSize: 12,
                  fontWeight: 300,
                }}
              />
            )}
            <NewTeamOrProjectIcon />
          </div>
        </div>
        <div className={style.teamsContainer}>
          {teamsWithProjects.map((team) => (
            <div key={team.id} className={style.teamContainer}>
              <div
                className={classNames(style.teamHeader, { [style.selectedTeam]: selectedTeamId === team.id })}
                onClick={() => {
                  handleTeamSelection(team);
                  toggleTeam(team.id);
                }}
              >
                <span className={style.teamToggle}>
                  {expandedTeams.includes(team.id) ? <OpenTeamIcon /> : <CloseTeamIcon />}
                </span>
                <TeamCellNew
                  key={team.id}
                  team={team}
                  isSelected={team.id === selectedTeamId}
                  onClick={() => {
                    handleTeamSelection(team);
                    toggleTeam(team.id);
                  }}
                  onEditSeats={onEditSeats}
                  handleNewProjectClick={handleNewProjectClick}
                  createNewBoard={createNewBoard}
                  onDeleteSelectedTeam={resetSelectedInstance}
                />
              </div>
              {renderProjects(team)}
            </div>
          ))}
        </div>
      </div>
      {showCreateProjectModal && projectParentTeamId && !isLoadingTeam && (
        <CreateProject
          parentTeamId={projectParentTeamId}
          createNewBoard={createNewBoard}
          onEditSeats={onEditSeats}
          setSelectedProject={setSelectedProject}
        />
      )}
      {showCreateTeamModal && (
        <CreateTeam
          onEditSeats={onEditSeats}
          onDismiss={() => setShowCreateTeamModal(false)}
          setSelectedTeam={setSelectedTeam}
        />
      )}
    </>
  );
}
