export function toCurrentTimezone(date: Date) {
  return date.getTime() - new Date().getTimezoneOffset() * 60 * 1000;
}

export const customLocale = (_number: number, index: number): [string, string] => {
  const locales: Array<[string, string]> = [
    ["just now", "right now"],
    ["%ss ago", "in %ss"], // seconds
    ["1min ago", "in 1 minute"], // singular minute
    ["%smin ago", "in %s minutes"], // plural minutes
    ["1h ago", "in 1 hour"], // singular hour
    ["%sh ago", "in %s hours"], // plural hours
    ["1d ago", "in 1 day"], // singular day
    ["%sd ago", "in %s days"], // plural days
    ["1w ago", "in 1 week"], // singular week
    ["%sw ago", "in %s weeks"], // plural weeks
    ["1mo ago", "in 1 month"], // singular month
    ["%smo ago", "in %s months"], // plural months
    ["1y ago", "in 1 year"], // singular year
    ["%sy ago", "in %s years"], // plural years
  ];
  return locales[index] || locales[0];
};
