import React from "react";
import style from "./home-page-refactor-announcement.module.css";
import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import { CloseIcon } from "frontend/icons/close";
import { AnnouncementIcon } from "frontend/icons/announcement";
import useWalkthroughTip from "frontend/walkthrough-tips/use-walkthrough-tip";
import { TipName } from "shared/datamodel/schemas/tip";
import { WalkthroughTipType } from "frontend/walkthrough-tips/walkthrough-tip-model";

const buttonSize = {
  width: 226,
  height: 45,
  borderRadius: 6,
  ontFamily: "Poppins",
  fontSize: 16,
  fontWeight: 400,
};

export default function HomePageRefactorAnnouncement({ onDismiss }: { onDismiss: () => void }) {
  const { markTipSeen } = useWalkthroughTip(TipName.homePageRefactor, null, WalkthroughTipType.HomePage);

  function onDismissWrapper(skip: boolean) {
    if (skip) {
      markTipSeen(TipName.homePageWalkthrough);
    }
    markTipSeen(TipName.homePageRefactor);
    onDismiss();
  }

  return (
    <div className={style.modalContainer}>
      <div className={style.closeIcon} onClick={() => onDismissWrapper(true)}>
        <CloseIcon />
      </div>
      <div className={style.container}>
        <AnnouncementIcon />
        <div className={style.contentWrapper}>
          <h2 className={style.title}>{`Get to know your homepage`}</h2>
          <p className={style.description}>{`Take a moment to explore the different sections and features.`}</p>
        </div>
        <div className={style.buttonContainer}>
          <StyledButton
            onClick={() => onDismissWrapper(true)}
            style={ButtonStyle.outline}
            title={"Skip"}
            customStyle={{ ...buttonSize, color: "#1973FF" }}
          />
          <StyledButton
            onClick={() => onDismissWrapper(false)}
            title={"Take a tour"}
            customStyle={{ ...buttonSize, color: "#FFF" }}
          />
        </div>
      </div>
    </div>
  );
}
