import { useRef, useState } from "react";
import style from "./side-menu.module.css";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import { Team } from "shared/datamodel/schemas/team";
import { Floater } from "frontend/ui-components/floaters/floater";
import { TeamCell } from "./teamCell";
import { CollaborationType, InviteMode } from "state-atoms/teams-atoms";
import { InitialsIcon } from "../../ui-components/initialsIcon";
import { addToLocalStorageArray, getLocalStorageItem, setLocalStorageItem } from "frontend/utils/storage-utils";
import Modal from "frontend/modal/modal";
import NewCollaborationModal from "frontend/modals/collaboration/new-collaboration-modal";
import { useHover } from "frontend/ui-components/floaters/use-hover";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";
import useAbility from "frontend/hooks/use-ability";
import useStateValue from "frontend/state/value";
import { InstanceType } from "shared/datamodel/schemas/ability";
import RestrictedUserModal from "frontend/modals/restricted-user-modal";
import InviteModal from "frontend/modals/invite-modal";
import { useTeam } from "frontend/hooks/use-team";

export function TeamPicker({
  selectedTeam,
  setSelectedTeam,
  onEditSeats,
  handleNoPermissionToCreate,
}: {
  selectedTeam: Team;
  setSelectedTeam: (team: Team) => void;
  onEditSeats: () => void;
  handleNoPermissionToCreate: () => void;
}) {
  const [teamName, setTeamName] = useState("");
  const [showTeamPickerMenu, setShowTeamPickerMenu] = useState(false);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [showInviteTeammatesModal, setShowInviteTeammatesModal] = useState(false);
  const [showRestrictedModal, setShowRestrictedModal] = useState(false);

  const teamRef = useRef(null);
  const teamPickerRef = useRef(null);
  const { myTeams } = useTeam();
  const nameRef = useRef<any>(null);
  const hover = useHover(nameRef);
  const { canPerformAnyAction, canCreateInstance } = useAbility();
  const [{ user }] = useStateValue();

  const dropdownArrow = (
    <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.11442 1L4.55713 4.55729" stroke="#11355E" strokeLinecap="square" />
      <path d="M1 1L4.55729 4.55729" stroke="#11355E" strokeLinecap="square" />
    </svg>
  );

  const plusIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
      <path d="M6.5 1V12V1Z" fill="#1973FF" />
      <path d="M1 6.5H12M6.5 1V12" stroke="#1973FF" strokeLinecap="square" strokeLinejoin="round" />
    </svg>
  );

  const handleTeamSelection = (team: Team) => {
    setSelectedTeam(team);
    const selectedTeamsAndProjects = getLocalStorageItem("selectedTeamsAndProjects");
    const selectedProjectId =
      selectedTeamsAndProjects.find((item: any) => item.selectedTeam === team.id)?.selectedProject ?? null;
    setLocalStorageItem(
      "selectedTeamsAndProjects",
      selectedTeamsAndProjects.filter((item: any) => item.selectedTeam !== team.id)
    );
    addToLocalStorageArray("selectedTeamsAndProjects", {
      selectedTeam: team.id,
      selectedProject: selectedProjectId,
    });
    setShowTeamPickerMenu(false);
  };

  function renderTeams() {
    if (myTeams) {
      return myTeams.length
        ? myTeams.map((team: Team) => (
            <TeamCell
              key={team.id}
              team={team}
              isSelected={team.id === selectedTeam.id}
              onClick={() => {
                handleTeamSelection(team);
              }}
              onDismiss={() => {
                setShowTeamPickerMenu(false);
              }}
              onEditSeats={onEditSeats}
            />
          ))
        : null;
    } else {
      return null;
    }
  }

  function handlePlusIconClick() {
    const shouldShowRestrictedModal = user && !canPerformAnyAction(user, selectedTeam, InstanceType.Team);
    if (shouldShowRestrictedModal) {
      setShowRestrictedModal(true);
    } else if (user && !canCreateInstance(user, selectedTeam, InstanceType.Team)) {
      handleNoPermissionToCreate();
    } else {
      setShowTeamPickerMenu(false);
      setShowCreateTeamModal(true);
      tracking.trackEvent(consts.TRACKING_CATEGORY.TEAM_ACTION, "create_team_clicked", "create_team", "team");
    }
  }

  const renderTeamPickerMenu = () => {
    return (
      <Modal onClickBackground={() => setShowTeamPickerMenu(false)}>
        <Floater
          relativeTo={teamRef}
          side={"bottom"}
          extraStyle={{
            marginTop: "-16px",
            zIndex: 100,
            filter: "drop-shadow(0px 4.799998760223389px 9.599997520446777px rgba(0, 0, 0, 0.20))",
            pointerEvents: "all",
          }}
        >
          <div className={style.arrow} />
          <div className={style.teamPickerMenu}>
            <div className={style.list}>{renderTeams()}</div>
            <div className={style.teamPickerMenuDivider}></div>
            <div
              data-testid="Create new team"
              className={style.teamPickerMenuButton}
              onClick={() => {
                tracking.trackAnalyticsEvent("create-team-clicked", {
                  category: consts.TRACKING_CATEGORY.TEAM_ACTION,
                  isNewHomePage: false,
                });
                handlePlusIconClick();
              }}
            >
              {plusIcon}
              <span className={style.createNewTeam}>Create new team</span>
            </div>
          </div>
        </Floater>
      </Modal>
    );
  };

  return (
    <div ref={teamPickerRef} data-testid="teampicker">
      <div className={style.teamPicker} onClick={() => setShowTeamPickerMenu(!showTeamPickerMenu)} ref={teamRef}>
        <div className={style.teamTitle}>
          {selectedTeam && <InitialsIcon text={selectedTeam.name} list={myTeams} />}
          <span className={style.teamName} ref={nameRef}>
            {selectedTeam?.name}
          </span>
          {hover && selectedTeam?.name.length > 14 && (
            <Tooltip side="bottom" label={selectedTeam?.name} relativeTo={nameRef} boundary="none" />
          )}
        </div>
        <span>{dropdownArrow}</span>
      </div>
      {showTeamPickerMenu && renderTeamPickerMenu()}
      {showCreateTeamModal && (
        <Modal dimBackground={true}>
          <NewCollaborationModal
            onDismiss={() => {
              setShowCreateTeamModal(false);
            }}
            onCreateTeam={(name: string) => {
              setTeamName(name);
              setShowCreateTeamModal(false);
              setShowInviteTeammatesModal(true);
            }}
            type={CollaborationType.Team}
            subtitle={"Name your team before invite your teammates to join."}
            inputPlaceholder={"Enter team name"}
          />
        </Modal>
      )}
      {showInviteTeammatesModal && (
        <Modal dimBackground={true}>
          <InviteModal
            name={teamName}
            onDismiss={() => setShowInviteTeammatesModal(false)}
            inviteMode={InviteMode.Create}
            onEditSeats={onEditSeats}
            source="create-team"
          />
        </Modal>
      )}
      {showRestrictedModal && (
        <RestrictedUserModal trackingEvent={"home"} onDismiss={() => setShowRestrictedModal(false)} />
      )}
    </div>
  );
}
